import { BookWrapper } from "../../../../src/collections/service-mesh-books/Book.style.js";
import Table from "../../../../src/components/service-mesh-patterns-Table/Table.js";
import { Link } from "gatsby";
import service_mesh_pattern_book from "../../../../src/collections/service-mesh-books/service-mesh-patterns/service-mesh-patterns.webp";
import * as React from 'react';
export default {
  BookWrapper,
  Table,
  Link,
  service_mesh_pattern_book,
  React
};